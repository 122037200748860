import React, { Component } from 'react'
import { Button, Container, Header, Icon, List, Loader, Segment, Grid, Dimmer, Divider, Popup } from 'semantic-ui-react'
import _ from 'lodash'
import { ArticleApi } from 'trill-api-admin-client'
import TrillDescription from 'trill-description'

import CancelablePromise from '../../CancelablePromise'
import GetPermission from '../../GetPermission'
import LogLevel from '../../LogLevel'

import './ArticlePreview.css'

const articleApi = new ArticleApi()
const logger = LogLevel.getLogger('ArticlePreview')

let getArticle

const ArticleStatus = {
  PUBLISH: 'publish',
  PENDING: 'pending',
  DRAFT: 'draft',
  TRASH: 'trash',
}

class ArticlePreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      permission: GetPermission('article'),
      articleId: null,
      article: {},
    }
  }

  async componentDidMount() {
    const articleId = this.props.routeParams.id

    if (!_.isNil(articleId)) {
      this.setState({ articleId })
      this.retrieveArticle(articleId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextArticleId = nextProps.routeParams.id

    if (!_.isNil(nextArticleId) && !_.isEqual(nextArticleId, this.state.articleId)) {
      this.setState({ articleId: nextArticleId })
      this.retrieveArticle(nextArticleId)
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getArticle)) {
      getArticle.cancel()
    }
  }

  async retrieveArticle(articleId) {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getArticle = CancelablePromise(
      articleApi.getArticle(articleId, { outputType: TrillDescription.HtmlOutputType.FULL_PAGE }),
    )
    getArticle.promise
      .then(response => {
        const article = response.data

        this.setState({
          isBusy: false,
          article,
        })
        this.previewArticleHtml(article)
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Preview で使用する html タグを TRILL Description を使用して取得
   * ただし、記事詳細取得時に admin としてデータを取得しているため SNS 埋め込みタグなどは含まれていない description から TRILL Description を使用して取得することになる
   * SNS 表示用の Script が付与されないため html 取得後に admin 側で iframe に各 SNS の script を設定することで表示可能とする
   */
  previewArticleHtml = article => {
    try {
      const html = article.description
      const iframeElement = this.refs.descriptionPreviewIframe
      if (!iframeElement) {
        return
      }

      iframeElement.src = `javascript: '${html.replace(/'/g, "\\'")}'`
      const iframeDocument = iframeElement.contentDocument
      iframeDocument.open()
      iframeDocument.write(html)
      iframeDocument.close()

      /**
       * Add custom styles
       */
      const customStyle = iframeDocument.createElement('style')
      customStyle.textContent = this.customTrillDescriptionStyleContent()
      iframeDocument.head.appendChild(customStyle)
    } catch (error) {
      logger.error('previewArticleHtml', error)
    }
  }

  customTrillDescriptionStyleContent = () => {
    const styleContent = []
    const { styleSheets } = document

    Array.prototype.slice.call(styleSheets).forEach(styleSheet => {
      try {
        const { cssRules } = styleSheet

        Array.prototype.slice.call(cssRules).forEach(cssRule => {
          const { cssText } = cssRule
          const trillDescriptionCssTexts = cssText.match(/\.ArticlePreview \.trill-description[\s\S]*?\{[^}]*\}/gim)

          if (trillDescriptionCssTexts) {
            styleContent.push(cssText.replace(/\.ArticlePreview \.trill-description/gim, '.trill-description'))
          }
        })
      } catch (error) {
        logger.error('Error accessing style sheet', error)
      }
    })

    return styleContent.join('\n')
  }

  render() {
    const { isBusy, permission, article } = this.state
    const { status, mediumRelatedArticles, originalArticle, outsourcedArticle } = article
    const { hasUpdatePermission } = permission

    const mediumRelatedArticlesTitlesRender = () => {
      if (_.isEmpty(mediumRelatedArticles)) {
        return null
      }

      return (
        <>
          <Divider />
          <div className="ArticlePreview__MediumRelatedArticlesTitles">
            <Header size="small">関連する他の記事</Header>
            <List>
              {mediumRelatedArticles.map((item, index) => (
                <List.Item key={index}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </List.Item>
              ))}
            </List>
          </div>
        </>
      )
    }

    const statusColor = () => {
      switch (article.status) {
        case ArticleStatus.PUBLISH:
          return 'green'
        case ArticleStatus.PENDING:
          return 'red'
        case ArticleStatus.DRAFT:
          return 'yellow'
        case ArticleStatus.TRASH:
          return 'grey'
        default:
          return ''
      }
    }

    return (
      <div className="ArticlePreview">
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="file text outline" />

              <Header.Content>記事本文のプレビューを表示</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Button.Group secondary>
              {_.get(originalArticle, 'link') && (
                <Popup
                  inverted
                  wide
                  content="元記事を見る"
                  trigger={
                    <Button
                      icon="file alternate outline"
                      as="a"
                      href={_.get(originalArticle, 'link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  }
                />
              )}

              {_.get(outsourcedArticle, 'id') && (
                <Popup
                  inverted
                  wide
                  content="編集委託記事"
                  trigger={
                    <Button
                      icon="users"
                      as="a"
                      href={`${process.env.REACT_APP_TRILL_OUTSOURCE_URL}/article/${article.id}?token=${_.get(
                        outsourcedArticle,
                        'id',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  }
                />
              )}

              <Popup
                inverted
                wide
                content="記事の編集"
                trigger={
                  <Button
                    icon="edit outline"
                    as="a"
                    href={`/article/${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!hasUpdatePermission}
                  />
                }
              />

              <Popup
                inverted
                wide
                content="TRILL Webサイトで見る"
                trigger={
                  <Button
                    icon="world"
                    as="a"
                    href={`${process.env.REACT_APP_TRILL_WEB_URL}/articles/${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!_.isEqual(status, 'publish')}
                  />
                }
              />
            </Button.Group>
          </Grid.Column>
        </Grid>
        <Divider hidden />

        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Segment
          color={statusColor()}
          style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 10.5rem)' }}
        >
          <Header as="h2">{article.title}</Header>

          <Divider />
          <Container fluid style={{ height: '100%' }}>
            <iframe
              title="記事本文のプレビュー"
              ref="descriptionPreviewIframe"
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </Container>

          {mediumRelatedArticlesTitlesRender()}
        </Segment>
      </div>
    )
  }
}

export default ArticlePreview
