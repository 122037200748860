import React, { Component } from 'react'
import { Segment, Header, Icon, Button, Statistic, Grid, Dropdown, Progress, Label, Popup } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import { GoogleAnalyticsApi } from 'trill-api-admin-client'
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarController,
  LineController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { FormattedDate, FormattedTime } from 'react-intl'
import moment from 'moment'
import 'moment/locale/ja'

import MediaDropdown from '../../../components/MediaDropdown'
import FormErrorLabel from '../../../components/FormErrorLabel'
import ApiErrorMessage from '../../../components/ApiErrorMessage'
import DataTable from '../../../components/DataTable'
import CancelablePromise from '../../../CancelablePromise'

import './RealtimeReport.css'

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarController,
  LineController,
  Filler,
  Legend,
  Title,
  Tooltip,
)

const googleAnalyticsApi = new GoogleAnalyticsApi()
let getRealtimeReport
let timerInterval = null

const inputTextValidateRegex = /^((?!,).)*$/i
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

class RealtimeReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      isFormSearchValid: false,
      isEmptyData: false,
      autoReloadTimerData: {
        isAllowReload: true,
        timeInterval: 30000, // milliseconds
        lastTime: Date.now(),
        lastUpdatedTime: null,
        countDowntime: 30000,
      },
      quotaData: {
        consumed: {},
        remaining: {},
      },
      commonData: {
        activePageCard: {
          total: 0,
        },
        activeUserCard: {
          total: 0,
        },
      },
      deviceChart: {
        data: {
          datasets: [
            {
              key: 'mobile',
              label: '携帯電話',
              color: 'green',
              total: 0,
              percent: 0,
            },
            {
              key: 'tablet',
              label: 'タブレット',
              color: 'red',
              total: 0,
              percent: 0,
            },
            {
              key: 'desktop',
              label: 'デスクトップ',
              color: 'blue',
              total: 0,
              percent: 0,
            },
            {
              key: '(other)',
              label: '他の',
              color: 'brown',
              total: 0,
              percent: 0,
            },
          ],
        },
      },
      minutesChart: {
        options: this.getMinutesChartOption(),
        data: {
          labels: [],
          datasets: [
            {
              type: 'bar',
              key: 'totalActiveUsers',
              label: 'アクティブ ユーザー',
              data: [],
              borderColor: 'rgb(33, 133, 208)',
              backgroundColor: 'rgba(33, 133, 208, 0.5)',
              hidden: true,
              lineTension: 0.4,
              yAxisID: 'y',
            },
            {
              type: 'line',
              key: 'averageActiveUsers',
              fill: false,
              label: 'アクティブユーザーの移動平均',
              data: [],
              borderColor: 'rgb(219, 40, 40)',
              backgroundColor: 'rgba(219, 40, 40, 0.5)',
              lineTension: 0.4,
              spanGaps: true,
              yAxisID: 'y',
            },
          ],
        },
        isFullScreen: false,
      },
      tableData: {
        items: [],
        currentPage: 1,
        itemsPerPage: 30,
        totalPages: 0,
        totalItems: 0,
        sorting: {
          activeUsers: 'desc',
        },
        filtering: {
          searchInput: {
            key: 'customUser:page_path',
            value: '',
          },
          minutesAgo: 30,
        },
      },
    }

    this.handleDocumentVisibilitychange = this.handleDocumentVisibilitychange.bind(this)
    this.handleDocumentFullScreenChange = this.handleDocumentFullScreenChange.bind(this)
    this.minutesChartRef = null
  }

  componentDidMount() {
    this.retrieveRealtimeReport()

    // window.addEventListener('visibilitychange', this.handleDocumentVisibilitychange)
    window.addEventListener('fullscreenchange', this.handleDocumentFullScreenChange)
  }

  componentWillUnmount() {
    if (!_.isNil(getRealtimeReport)) {
      getRealtimeReport.cancel()
    }

    if (!_.isNil(timerInterval)) {
      clearInterval(timerInterval)
    }

    // window.removeEventListener('visibilitychange', this.handleDocumentVisibilitychange)
    window.removeEventListener('fullscreenchange', this.handleDocumentFullScreenChange)
  }

  initialAutoReloadTimerInterval = () => {
    if (!_.isNil(timerInterval)) {
      clearInterval(timerInterval)
    }

    timerInterval = setInterval(() => {
      const { autoReloadTimerData } = this.state
      const { isAllowReload, timeInterval, lastTime, countDowntime } = autoReloadTimerData
      const newCountDowntime = Math.max(timeInterval - (Date.now() - lastTime), 0)

      if (newCountDowntime !== countDowntime) {
        autoReloadTimerData.countDowntime = newCountDowntime
        this.setState({ autoReloadTimerData }, () => {
          if (!newCountDowntime && isAllowReload) {
            this.retrieveRealtimeReport()
          }
        })
      }
    }, 1000)
  }

  handleDocumentVisibilitychange() {
    const { autoReloadTimerData } = this.state

    if (!_.isNil(timerInterval)) {
      clearInterval(timerInterval)
    }

    if (document.visibilityState === 'visible') {
      autoReloadTimerData.isAllowReload = true

      if (Date.now() - autoReloadTimerData.lastTime > autoReloadTimerData.countDowntime) {
        this.retrieveRealtimeReport()
      }
    } else {
      autoReloadTimerData.isAllowReload = false
    }

    this.setState({ autoReloadTimerData })
  }

  handleResizeMinutesChartElement() {
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    const isMinutesChartFullScreen = fullscreenElement === this.minutesChartRef

    if (isMinutesChartFullScreen === this.state.minutesChart.isFullScreen) {
      return
    }

    let minutesChart = this.state.minutesChart

    if (isMinutesChartFullScreen) {
      minutesChart = {
        ...minutesChart,
        options: this.getMinutesChartOption({
          fontSize: '12rem',
          padding: 15,
          radius: 2,
          borderWidth: 2,
        }),
        isFullScreen: true,
      }
    } else {
      minutesChart = {
        ...minutesChart,
        options: this.getMinutesChartOption(),
        isFullScreen: false,
      }
    }

    minutesChart.isFullScreen = isMinutesChartFullScreen
    this.setState({ minutesChart })
  }

  handleDocumentFullScreenChange() {
    this.handleResizeMinutesChartElement()
  }

  getMinutesChartOption = (style = {}) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: style.color || 'rgba(0,0,0,.87)',
          font: {
            size: style.fontSize || '9em',
          },
          padding: style.padding || 7.5,
        },
      },
    },
    elements: {
      point: {
        radius: style.radius || 1,
        hoverRadius: (style.radius || 1) + 1,
      },
      line: {
        borderWidth: style.borderWidth || 1,
      },
      bar: {
        borderWidth: style.borderWidth || 1,
      },
    },
    scales: {
      y: {
        ticks: {
          color: style.color || 'rgba(0,0,0,.87)',
          font: {
            size: style.fontSize || '9em',
          },
        },
      },
      x: {
        ticks: {
          color: style.color || 'rgba(0,0,0,.87)',
          font: {
            size: style.fontSize || '9em',
          },
        },
      },
    },
  })

  handleMinutesAgoDropdownChange = (event, data) => {
    const { tableData } = this.state
    tableData.filtering.minutesAgo = data.value

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleDeviceCategoryClick = deviceCategory => {
    const { tableData } = this.state
    tableData.filtering.deviceCategory = deviceCategory

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleToggleFullScreenClick = () => {
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement

    if (fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    } else if (this.minutesChartRef) {
      if (this.minutesChartRef.requestFullscreen) {
        this.minutesChartRef.requestFullscreen()
      } else if (this.minutesChartRef.msRequestFullscreen) {
        this.minutesChartRef.msRequestFullscreen()
      } else if (this.minutesChartRef.mozRequestFullScreen) {
        this.minutesChartRef.mozRequestFullScreen()
      } else if (this.minutesChartRef.webkitRequestFullscreen) {
        this.minutesChartRef.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    }
  }

  handleRemoveDeviceCategoryIconClick = () => {
    const { tableData } = this.state
    tableData.filtering.deviceCategory = ''

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  handleSearchInputChange = (event, data) => {
    const { tableData } = this.state
    tableData.filtering.searchInput.value = data.value

    this.setState({ tableData })
  }

  handleSearchInputTypeDropdownChange = (event, { value }) => {
    const { tableData } = this.state
    tableData.filtering.searchInput.key = value
    tableData.filtering.searchInput.value = ''

    this.setState({ tableData })
  }

  handleSearchButtonClick = event => {
    event.preventDefault()

    const { tableData } = this.state
    tableData.currentPage = 1

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleFilteringDropdownChange = (event, { name, value }) => {
    const { tableData } = this.state
    const filterKey = `customUser:${camelToSnakeCase(name)}`

    if (value) {
      tableData.filtering[filterKey] = value
      // eslint-disable-next-line no-prototype-builtins
    } else if (tableData.filtering.hasOwnProperty(filterKey)) {
      delete tableData.filtering[filterKey]
    }

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleDataTableSortChange = (event, { sort }) => {
    const { isEmptyData, tableData } = this.state

    if (isEmptyData) {
      return
    }

    const sortingKeys = _.keys(tableData.sorting)
    _.each(_.keys(sort), key => {
      if (!_.includes(sortingKeys, key)) {
        sort[key] = 'desc'
      }
    })

    tableData.sorting = sort
    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleItemSearchButtonClick = item => {
    const { tableData } = this.state
    tableData.filtering['customUser:page_path'] = item['customUser:pagePath']

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleRemoveItemSearchButtonClick = () => {
    const { tableData } = this.state
    tableData.filtering['customUser:page_path'] = ''

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const { isEmptyData, tableData } = this.state

    if (isEmptyData) {
      return
    }

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveRealtimeReport()
    })
  }

  retrieveRealtimeReport = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getRealtimeReport = CancelablePromise(googleAnalyticsApi.getRealtimeReport(this.getRequestQuery()))
    getRealtimeReport.promise
      .then(response => {
        const { autoReloadTimerData, quotaData } = this.state
        const commonData = _.cloneDeep(this.state.commonData)
        const minutesChart = _.cloneDeep(this.state.minutesChart)
        const deviceChart = _.cloneDeep(this.state.deviceChart)
        const tableData = _.cloneDeep(this.state.tableData)
        const { itemsPerPage } = tableData
        const report = response.data
        const reportCommon = _.get(report, 'common.items', [])[0]
        const reportByMinutesItems = _.get(report, 'byMinutes.items', [])
        const reportByDeviceCategoryItems = _.get(report, 'byDeviceCategory.items', [])
        const reportByScreenNameItems = _.get(report, 'byScreenName.items', [])
        const totalActivePages = +_.get(report, 'byScreenName.totalItems', 0)
        const totalActiveUsers = +_.get(reportCommon, 'activeUsers', 0)
        const isEmptyData =
          _.isEmpty(reportByMinutesItems) &&
          _.isEmpty(reportByDeviceCategoryItems) &&
          _.isEmpty(reportByScreenNameItems)

        const remainingQuotaData = {}
        _.each(['common', 'byMinutes', 'byDeviceCategory', 'byScreenName'], key => {
          quotaData.consumed[key] = _.get(report, `${key}.quota.tokensPerHour.consumed`, 0)

          _.each(['tokensPerHour', 'tokensPerDay'], quotaKey => {
            const remaining = _.get(report, `${key}.quota.${quotaKey}.remaining`, 0)

            if (!remainingQuotaData[quotaKey] || remainingQuotaData[quotaKey] > remaining) {
              remainingQuotaData[quotaKey] = remaining
            }
          })
        })
        quotaData.remaining = remainingQuotaData

        commonData.activePageCard.total = totalActivePages
        commonData.activeUserCard.total = totalActiveUsers

        const minutesAgo = _.get(tableData, 'filtering.minutesAgo', 59)
        const minutesChartObjectData = {}
        _.each(reportByMinutesItems, item => {
          const activeUsers = +item.activeUsers || 0

          if (minutesChartObjectData[item.minutesAgo]) {
            minutesChartObjectData[item.minutesAgo].totalActiveUsers += activeUsers
          } else {
            minutesChartObjectData[item.minutesAgo] = {
              totalActiveUsers: activeUsers,
            }
          }
        })
        const minutesBlocks = _.map(new Array(minutesAgo + 1), (item, index) => minutesAgo - index)
        minutesChart.data.labels = _.map(minutesBlocks, minutes => `-${minutes} 分`)
        _.each(_.get(minutesChart, 'data.datasets', []), dataset => {
          if (dataset.key === 'averageActiveUsers') {
            const averageBlock = 2
            const averageActiveUsers = []
            const pushAverageActiveUsers = index => {
              const blocks = minutesBlocks.slice(index, index + averageBlock)
              const sum = _.reduce(
                blocks,
                (result, minutes) => {
                  const item = _.get(
                    minutesChartObjectData,
                    `${minutes.toString().padStart(2, '0')}.totalActiveUsers`,
                    0,
                  )

                  return result + item
                },
                0,
              )
              const average = Math.round((sum / blocks.length) * 100) / 100
              averageActiveUsers.push(average)
            }

            _.each(minutesBlocks, (__, index) => {
              if (index % averageBlock === 0) {
                pushAverageActiveUsers(index)
                return
              }

              if (index === minutesAgo) {
                const startIndex = index - (index % averageBlock) + 1
                pushAverageActiveUsers(startIndex)
                return
              }

              averageActiveUsers.push(undefined)
            })

            dataset.data = averageActiveUsers
            return
          }

          dataset.data = _.map(minutesBlocks, minutes =>
            _.get(minutesChartObjectData, `${minutes.toString().padStart(2, '0')}.${dataset.key}`, 0),
          )
        })

        let totalDevice = 0
        const deviceChartObjectData = {}
        _.each(reportByDeviceCategoryItems, item => {
          const activeUsers = +item.activeUsers || 0

          totalDevice += activeUsers
          if (deviceChartObjectData[item.deviceCategory]) {
            deviceChartObjectData[item.deviceCategory].totalActiveUsers += activeUsers
          } else {
            deviceChartObjectData[item.deviceCategory] = {
              totalActiveUsers: activeUsers,
            }
          }
        })
        _.each(_.get(deviceChart, 'data.datasets', []), dataset => {
          const total = _.get(deviceChartObjectData, `${dataset.key}.totalActiveUsers`, 0)
          const percent = total === 0 || totalDevice === 0 ? 0 : total / totalDevice

          dataset.total = total
          dataset.percent = Math.round(percent * 10000) / 100
        })

        tableData.totalItems = totalActivePages
        tableData.totalPages = Math.ceil(totalActivePages / itemsPerPage)
        tableData.items = _.map(reportByScreenNameItems, (item, index) => ({
          ...item,
          index: index + 1,
          activeUsers: +item.activeUsers || 0,
        }))

        autoReloadTimerData.lastTime = Date.now()
        autoReloadTimerData.lastUpdatedTime = Date.now()
        autoReloadTimerData.countDowntime = autoReloadTimerData.timeInterval

        this.setState(
          {
            isBusy: false,
            isEmptyData,
            autoReloadTimerData,
            quotaData,
            commonData,
            deviceChart,
            minutesChart,
            tableData,
          },
          () => {
            /**
             * TODO: Temporarily no auto-reload feature will be developed
             * this.initialAutoReloadTimerInterval()
             */
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        const autoReloadTimerData = _.cloneDeep(this.state.autoReloadTimerData)

        autoReloadTimerData.lastTime = Date.now()
        autoReloadTimerData.countDowntime = autoReloadTimerData.timeInterval

        this.setState(
          {
            isBusy: false,
            apiError: error,
            autoReloadTimerData,
          },
          () => {
            /**
             * TODO: Temporarily no auto-reload feature will be developed
             * this.initialAutoReloadTimerInterval()
             */
          },
        )
      })
  }

  getRequestQuery = () => {
    const { tableData } = this.state

    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    _.each(_.keys(tableData.filtering), filterKey => {
      const isSearchInputFilter = _.isEqual(filterKey, 'searchInput')
      const key = isSearchInputFilter ? tableData.filtering[filterKey].key : filterKey
      const operator = isSearchInputFilter ? 'LIKE' : '='
      const value = isSearchInputFilter
        ? tableData.filtering[filterKey].value.toString()
        : tableData.filtering[filterKey].toString()

      if (!_.isEmpty(value) && value.match(inputTextValidateRegex)) {
        filtering.push(`${key} ${operator} "${value.trim()}"`)
      }
    })

    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      itemsPerPage,
      currentPage,
      sorting,
      filtering,
    }

    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const {
      isBusy,
      apiError,
      isFormSearchValid,
      isEmptyData,
      autoReloadTimerData,
      quotaData,
      commonData,
      deviceChart,
      minutesChart,
      tableData,
    } = this.state

    const searchInputPlaceholder = () => {
      switch (tableData.filtering.searchInput.key) {
        case 'customUser:page_path':
          return 'ページで検索'
        case 'unifiedScreenName':
          return 'タイトルで検索'
        default:
          return ''
      }
    }

    const popupQuotaRender = () => {
      const totalConsumed = _.reduce(_.values(quotaData.consumed), (result, value) => result + value, 0)
      const tokensPerHourRemaining = _.get(quotaData, 'remaining.tokensPerHour', 0)
      const tokensPerDayRemaining = _.get(quotaData, 'remaining.tokensPerDay', 0)
      const currentDayString = moment().format('YYYY/MM/DD')
      const yesterdayString = moment()
        .add(1, 'days')
        .format('YYYY/MM/DD')
      const tomorrowString = moment()
        .add(-1, 'days')
        .format('YYYY/MM/DD')
      const dayRemaining =
        moment().hour() > 17
          ? `（${currentDayString} 17:00:00~${tomorrowString} 16:59:59）`
          : `（${yesterdayString} 17:00:00~${currentDayString} 16:59:59）`
      const isWarning = totalConsumed * 10 > tokensPerHourRemaining || totalConsumed * 10 > tokensPerDayRemaining
      const isError = totalConsumed > tokensPerHourRemaining || totalConsumed > tokensPerDayRemaining
      const popupTriggerRender = () => {
        if (isError) {
          return <Icon name="warning circle" color="red" />
        }

        if (isWarning) {
          return <Icon name="warning sign" color="yellow" />
        }

        return <Icon name="info circle" color="blue" />
      }

      return (
        <Popup
          trigger={popupTriggerRender()}
          flowing
          hoverable
          position="bottom center"
          className="RealtimeReportGoogleAnalytics__Popup--Quota"
        >
          <Grid centered divided>
            <Grid.Column width={7}>
              <Header as="h3">最新リフレッシュの消費トークン </Header>
              <Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">全体の統計</Segment>
                  <Segment textAlign="right">
                    {_.get(quotaData, 'consumed.common', 0).toLocaleString('ja-JP')} トークン
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">分単位の統計</Segment>
                  <Segment textAlign="right">
                    {_.get(quotaData, 'consumed.byMinutes', 0).toLocaleString('ja-JP')} トークン
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">デバイス別の統計</Segment>
                  <Segment textAlign="right">
                    {_.get(quotaData, 'consumed.byDeviceCategory', 0).toLocaleString('ja-JP')} トークン
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">ページごとの統計</Segment>
                  <Segment textAlign="right">
                    {_.get(quotaData, 'consumed.byScreenName', 0).toLocaleString('ja-JP')} トークン
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment basic textAlign="left">
                    <Header as="h4">合計</Header>
                  </Segment>
                  <Segment basic textAlign="right">
                    <Header.Content>{totalConsumed.toLocaleString('ja-JP')} トークン</Header.Content>
                  </Segment>
                </Segment.Group>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column width={9}>
              <Header as="h3">残りトークン</Header>
              <Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">
                    <Header as="h4">1時間のトークン数</Header>
                  </Segment>
                  <Segment textAlign="right">
                    <Header.Content>{tokensPerHourRemaining.toLocaleString('ja-JP')} トークン</Header.Content>
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment textAlign="left">
                    <Header as="h4">1日のトークン数</Header>
                    <Header.Content>{dayRemaining}</Header.Content>
                  </Segment>
                  <Segment textAlign="right">
                    <Header.Content>{tokensPerDayRemaining.toLocaleString('ja-JP')} トークン</Header.Content>
                  </Segment>
                </Segment.Group>
              </Segment.Group>
            </Grid.Column>
          </Grid>
        </Popup>
      )
    }

    return (
      <div className="RealtimeReportGoogleAnalytics">
        <Grid verticalAlign="middle" className="RealtimeReportGoogleAnalytics__Grid">
          <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--Header">
            <Grid.Column>
              <Header as="h2">
                <Icon name="chart bar outline" />

                <Header.Content>リアルタイムレポート</Header.Content>

                {popupQuotaRender()}
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Segment basic className="RealtimeReportGoogleAnalytics__Segment--Dropdown">
                <Header as="h5">過去</Header>
                <Dropdown
                  onChange={this.handleMinutesAgoDropdownChange}
                  value={tableData.filtering.minutesAgo}
                  selection
                  options={[
                    {
                      value: 15,
                      text: 15,
                    },
                    {
                      value: 30,
                      text: 30,
                    },
                    {
                      value: 45,
                      text: 45,
                    },
                    {
                      value: 59,
                      text: 59,
                    },
                  ]}
                />
                <Header as="h5">分間</Header>
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Button
                primary
                size="tiny"
                loading={isBusy}
                content="リロード"
                icon="redo alternate"
                labelPosition="left"
                onClick={() => {
                  this.retrieveRealtimeReport()
                }}
              />
            </Grid.Column>

            {autoReloadTimerData.lastUpdatedTime && (
              <Grid.Column>
                <Label size="small">
                  更新日時: &nbsp;
                  <FormattedDate
                    value={autoReloadTimerData.lastUpdatedTime}
                    day="numeric"
                    month="long"
                    year="numeric"
                    weekday="narrow"
                  />
                  &nbsp;
                  <FormattedTime
                    value={autoReloadTimerData.lastUpdatedTime}
                    hour="numeric"
                    minute="numeric"
                    second="numeric"
                  />
                </Label>
              </Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--Error">
            <ApiErrorMessage error={apiError} />
          </Grid.Row>

          <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--Summary">
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Segment placeholder className="RealtimeReportGoogleAnalytics__Segment--Card">
                <Header as="h4">
                  <Icon name="users" color="blue" />
                  アクティブ ユーザー
                </Header>
                <Statistic horizontal color="black" size="large">
                  <Statistic.Value>
                    {_.get(commonData, 'activeUserCard.total', 0).toLocaleString('ja-JP')}
                  </Statistic.Value>
                  <Statistic.Label>人</Statistic.Label>
                </Statistic>
              </Segment>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Segment placeholder className="RealtimeReportGoogleAnalytics__Segment--Card">
                <Header as="h4">
                  <Icon name="file alternate outline" color="pink" />
                  アクティブなページ
                </Header>
                <Statistic horizontal color="black" size="large">
                  <Statistic.Value>
                    {_.get(commonData, 'activePageCard.total', 0).toLocaleString('ja-JP')}
                  </Statistic.Value>
                  <Statistic.Label>ページ</Statistic.Label>
                </Statistic>
              </Segment>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div
                ref={ref => {
                  this.minutesChartRef = ref
                }}
                className={`RealtimeReportGoogleAnalytics__MinutesChart${
                  minutesChart.isFullScreen ? ' RealtimeReportGoogleAnalytics__MinutesChart--FullScreen' : ''
                }`}
              >
                <Segment placeholder className="RealtimeReportGoogleAnalytics__Segment--Chart">
                  <div className="RealtimeReportGoogleAnalytics__Segment--Chart__Header">
                    <Header as="h4">
                      <Icon name="area chart" />
                      過去{tableData.filtering.minutesAgo}分間の統計
                    </Header>

                    <Button icon primary size="tiny" onClick={this.handleToggleFullScreenClick}>
                      <Icon name={minutesChart.isFullScreen ? 'compress' : 'expand'} />
                    </Button>
                  </div>

                  <div className="RealtimeReportGoogleAnalytics__Segment--Chart__Content">
                    <Chart
                      type="bar"
                      options={minutesChart.options}
                      data={minutesChart.data}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--Search">
            <Grid.Column width={6}>
              <Grid>
                <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--DeviceLabel">
                  {deviceChart.data.datasets.map(dataset => {
                    const isSearchingByDeviceCategory = _.isEqual(tableData.filtering.deviceCategory, dataset.key)

                    return (
                      <Grid.Column
                        key={`device-label-${dataset.key}`}
                        style={{ display: dataset.percent || isSearchingByDeviceCategory ? '' : 'none' }}
                      >
                        <Progress
                          size="small"
                          percent={100}
                          color={dataset.color}
                          title={`${dataset.total} デバイス ~ ${dataset.percent}% ${dataset.label}`}
                          onClick={() => this.handleDeviceCategoryClick(dataset.key)}
                        >
                          {dataset.label}
                          {isSearchingByDeviceCategory && (
                            <Icon
                              name="delete"
                              color="grey"
                              onClick={event => {
                                event.stopPropagation()
                                this.handleRemoveDeviceCategoryIconClick()
                              }}
                            />
                          )}
                        </Progress>
                      </Grid.Column>
                    )
                  })}
                </Grid.Row>

                {!isEmptyData && (
                  <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--DeviceValue">
                    {deviceChart.data.datasets.map(dataset => (
                      <Grid.Column key={`device-value-${dataset.key}`} style={{ width: `${dataset.percent}%` }}>
                        <Progress
                          size="small"
                          percent={dataset.percent}
                          color={dataset.color}
                          progress
                          title={`${dataset.total} デバイス ~ ${dataset.percent}% ${dataset.label}`}
                          onClick={() => this.handleDeviceCategoryClick(dataset.key)}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                )}
              </Grid>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form size="tiny" onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
                <Form.Group>
                  <Form.Field width={4}>
                    <MediaDropdown
                      ignoreTrashContents={false}
                      mediumId={tableData.filtering['customUser:medium_id']}
                      onChange={this.handleFilteringDropdownChange}
                      disabled={!isFormSearchValid}
                    />
                  </Form.Field>

                  <Form.Field width={12}>
                    <Input
                      size="mini"
                      name="realtime-report-search"
                      type="text"
                      placeholder={searchInputPlaceholder()}
                      action
                      fluid
                      value={tableData.filtering.searchInput.value}
                      onChange={this.handleSearchInputChange}
                      validations={{ matchRegexp: inputTextValidateRegex }}
                      validationErrors={{
                        matchRegexp: 'キーワードに不正な記号があるため検索できません',
                      }}
                      errorLabel={<FormErrorLabel />}
                    >
                      <input />

                      <Dropdown
                        onChange={this.handleSearchInputTypeDropdownChange}
                        value={tableData.filtering.searchInput.key}
                        selection
                        options={[
                          {
                            key: 'customUser:page_path',
                            value: 'customUser:page_path',
                            text: 'ページ',
                          },
                          {
                            key: 'unifiedScreenName',
                            value: 'unifiedScreenName',
                            text: 'タイトル',
                          },
                        ]}
                      />

                      <Button
                        size="tiny"
                        icon="search"
                        onClick={this.handleSearchButtonClick}
                        color="blue"
                        disabled={!isFormSearchValid}
                      />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="RealtimeReportGoogleAnalytics__Grid__Row--Table">
            <Grid.Column width={16}>
              <DataTable
                rowKey="index"
                size="small"
                sort={tableData.sorting}
                items={tableData.items}
                currentPage={tableData.currentPage}
                // totalPages={tableData.totalPages}
                itemsPerPage={tableData.itemsPerPage}
                onSelectionChange={this.handleDataTableSortChange}
                onPageChange={this.handleDataTablePageChange}
                columns={[
                  {
                    label: '',
                    align: 'center',
                    collapsing: false,
                    minWidth: '3em',
                    render: item => item.index,
                  },
                  {
                    label: 'ページ',
                    collapsing: false,
                    minWidth: '8em',
                    render: item => <span>{item['customUser:pagePath']}</span>,
                  },
                  {
                    label: 'タイトル',
                    collapsing: false,
                    cellClassName: 'ScreenName',
                    minWidth: '8em',
                    render: item => (
                      <>
                        <span>{item.unifiedScreenName}</span>
                        <a
                          href={process.env.REACT_APP_TRILL_WEB_URL + item['customUser:pagePath']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon name="external" />
                        </a>
                      </>
                    ),
                  },
                  {
                    label: 'メディア',
                    collapsing: false,
                    minWidth: '8em',
                    render: item => <span>{item['customUser:mediumName']}</span>,
                  },
                  {
                    labelRender: () => (
                      <span>
                        アクティブ
                        <br />
                        ユーザー
                      </span>
                    ),
                    field: 'activeUsers',
                    align: 'center',
                    collapsing: false,
                    minWidth: '8em',
                    render: item => <strong>{_.get(item, 'activeUsers', 0).toLocaleString('ja-JP')}</strong>,
                  },
                  {
                    label: '操作',
                    align: 'center',
                    minWidth: '8em',
                    render: item => {
                      if (_.isEqual(tableData.filtering['customUser:page_path'], item['customUser:pagePath'])) {
                        return <Button color="grey" icon="delete" onClick={this.handleRemoveItemSearchButtonClick} />
                      }

                      return (
                        <Button
                          size="tiny"
                          primary
                          icon="eye"
                          onClick={() => {
                            this.handleItemSearchButtonClick(item)
                          }}
                        />
                      )
                    },
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default RealtimeReport
