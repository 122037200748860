/**
 * Article
 */

export const PUBLISH_ARTICLE_REQUIRE_ATTRIBUTES = [
  'title',
  'description',
  'mediumId',
  'mediumItemId',
  'thumbnailImageUrl',
]
