import React from 'react'
import Article from './Article'

import TrillDescription from 'trill-description'
import 'bootstrap-daterangepicker/daterangepicker.css'
import _ from 'lodash'
import moment from 'moment'
import { ArticleApi, UserApi, DraftApi } from 'trill-api-admin-client'

import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import { differenceObject, flattenObject, getOutsourceArticleValue } from '../../util'

const logger = LogLevel.getLogger('ArticleContainer')
const articleApi = new ArticleApi()
let getArticle
let patchArticle
let restoreArticle
const getArticleTitleTags = []
const getArticleDescriptionTags = []

const userApi = new UserApi()
const draftApi = new DraftApi()

/**
 * 記事の状態
 * @enum {string}
 */
const ArticleStatus = {
  /** 公開 */
  PUBLISH: 'publish',
  /** 保留 */
  PENDING: 'pending',
  /** 下書き */
  DRAFT: 'draft',
  /** ゴミ箱 */
  TRASH: 'trash',
}

const draftItems = ['title', 'summary', 'description']

function convertTextToDatetime(publishDatetime) {
  return _.isEmpty(publishDatetime) ? null : moment(publishDatetime)
}

export default class ArticleContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articleId: null,
      articleStatus: null,

      categoryId: null,
      managementCategoryId: null,
      medium: null,
      mediumId: null,
      mediumItemId: null,
      creator: null,
      series: null,
      sponsorId: null,
      isAffiliated: false,
      summary: '',

      isNew: true,
      draftExists: false,

      isFormInitialized: false,
      isArticleInitialized: false,
      isFormValid: false,
      isFormModified: false,
      isTitleTagModified: false,
      isDescriptionTagModified: false,
      isSummaryValid: true,
      isSummaryModified: false,

      viewSidebarStatus: 'meta',

      descriptionInputValue: '',
      descriptionError: null,

      publishDatetime: null,

      descriptionTagsDropdownValue: [],

      titleTagsDropdownValue: [],

      thumbnailImageUrlInputValue: '',
      isThumbnailImageModified: false,
      thumbnailImageError: null,

      isCoverImageActive: false,
      coverImageUrlInputValue: '',
      isCoverImageModified: false,
      coverImageError: null,

      isConflictDraftKey: false,

      originalArticleLink: '',
      outsourcedArticleId: '',
      isDisableArticlePreviewButton: true,

      isShowPinterestRssLink: false,

      editorWarning: false,

      warningModalMessage: '',
      isWarningModalOpen: false,

      currentFormValues: {},

      isDuringSubmit: false,
      isBusy: false,
      apiError: null,
    }

    this.draftArticle = {
      title: undefined,
      summary: undefined,
      description: undefined,
    }

    this.debouncedTitleAutoSave = _.debounce(this.titleAutoSave, 5000)
    this.debouncedSummaryAutoSave = _.debounce(this.summaryAutoSave, 5000)
    this.debouncedDescriptionAutoSave = _.debounce(this.descriptionAutoSave, 5000)
    this.debouncedAutoSave = _.debounce(this.autoSave, 25000)
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this)
  }
  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * 初期のタイトルに紐づくタグ ID 一覧
   */
  initialTitleTagIds = []

  /**
   * 初期の本文に紐づくタグ ID 一覧
   */
  initialDescriptionTagIds = []

  async componentDidMount() {
    const articleId = this.props.routeParams.id
    const fromOutsourcedArticle = _.get(this.props, 'location.query.fromOutsourcedArticle') === 'true'

    if (!_.isNil(articleId)) {
      this.setState({
        articleId,
        isNew: false,
      })

      await this.retrieveDraft(articleId)

      await this.retrieveArticle(articleId, fromOutsourcedArticle)

      this.setState({
        isFormInitialized: true,
        isArticleInitialized: true,
      })
    } else {
      const draftArticleId = await this.retrieveCurrentUser()

      this.setState({ articleId: draftArticleId })

      await this.retrieveDraft(draftArticleId)

      if (this.state.draftExists) {
        const draftValues = {
          title: this.draftArticle.title,
          summary: this.draftArticle.summary,
        }
        this.form.reset(_.assign({}, this.initialFormValues, draftValues))
        this.setState({ descriptionInputValue: this.draftArticle.description })
      }

      this.setState({
        isFormInitialized: true,
        isArticleInitialized: true,
      })
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextArticleId = nextProps.routeParams.id

    if (!_.isNil(nextArticleId) && nextArticleId !== this.state.articleId) {
      this.setState({ articleId: nextArticleId })

      this.retrieveArticle(nextArticleId)
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getArticle)) {
      getArticle.cancel()
    }

    if (!_.isNil(patchArticle)) {
      patchArticle.cancel()
    }

    if (!_.isNil(restoreArticle)) {
      restoreArticle.cancel()
    }

    if (!_.isEmpty(getArticleTitleTags)) {
      _.each(getArticleTitleTags, getArticleTitleTag => getArticleTitleTag.cancel())
      getArticleTitleTags.length = 0
    }

    if (!_.isEmpty(getArticleDescriptionTags)) {
      _.each(getArticleDescriptionTags, getArticleDescriptionTag => getArticleDescriptionTag.cancel())
      getArticleDescriptionTags.length = 0
    }

    this.handleFlushAutoSaveDraftBeforeUnload()
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  handleBeforeUnload(e) {
    this.handleFlushAutoSaveDraftBeforeUnload()
  }

  handleFlushAutoSaveDraftBeforeUnload = () => {
    this.debouncedTitleAutoSave.flush()
    this.debouncedSummaryAutoSave.flush()
    this.debouncedDescriptionAutoSave.flush()
    this.debouncedAutoSave.flush()
  }

  retrieveCurrentUser = async () => {
    let draftArticleId

    try {
      const currentUser = await userApi.getCurrentUser()
      logger.debug('GET current user API called successfully. Returned response: ', currentUser)
      draftArticleId = currentUser.data.draftArticleId
    } catch (error) {
      logger.debug(error)
    }

    return draftArticleId
  }

  createNewDraftArticles = () => {
    const newDraftArticles = _.reduce(
      draftItems,
      (result, draftItem) => {
        result[draftItem] =
          this.draftArticle[draftItem] || this.state[draftItem] === ''
            ? this.draftArticle[draftItem]
            : this.initialFormValues[draftItem]

        return result
      },
      {},
    )

    return newDraftArticles
  }

  autoSave = () => {
    const opts = {
      newDraftArticles: this.createNewDraftArticles(),
    }

    draftApi.postDraftArticle(this.state.articleId, opts).then(
      response => {
        logger.debug('POST draft API called successfully. Returned response: ', response)
        this.setState({ draftExists: true })
      },
      error => {
        logger.debug(error)
      },
    )
  }

  revertChange = async () => {
    this.setState({ isFormInitialized: false })

    try {
      await draftApi.deleteDraftArticle(this.state.articleId)
      logger.debug('DELETE API called successfully.')
      this.setState({ draftExists: false })
    } catch (error) {
      logger.debug(error)
    }

    this.draftArticle = {
      title: undefined,
      summary: undefined,
      description: undefined,
    }

    await this.retrieveArticle(this.state.articleId)

    this.setState({ isFormInitialized: true })
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentFormValues, isChanged) => {
    /**
     * When we use currentFormValues, property summary is not present in currentFormValues.
     * Because property summary still exists and is used in initialFormValues.
     * Therefore, here we add it to currentFormValues compare with initialFormValues.
     */
    const currentValues = {
      ...currentFormValues,
      summary: this.state.summary,
    }
    const flattenCurrentValues = flattenObject(currentValues)
    const flattenInitialValues = flattenObject(this.initialFormValues)

    // 現在のフォームデータを初期のフォームデータと比較
    const isFormModified = !_.isEqual(flattenCurrentValues, flattenInitialValues)
    const partialState = {
      currentFormValues: currentValues,
    }

    // フォームの値に (変更あり から 変更なし) または (変更なし から 変更あり) に変わったタイミングのみ state を変更
    if (!_.isEqual(this.state.isFormModified, isFormModified)) {
      partialState.isFormModified = isFormModified
    }

    const isShowPinterestRssLink = this.initialFormValues.isPinterestAutoPublish && currentValues.isPinterestAutoPublish
    if (!_.isEqual(this.state.isShowPinterestRssLink, isShowPinterestRssLink)) {
      partialState.isShowPinterestRssLink = isShowPinterestRssLink
    }

    this.setState(partialState)
  }

  titleAutoSave = title => {
    if (this.state.isFormInitialized) {
      this.draftArticle = {
        ...this.draftArticle,
        title,
      }
      this.debouncedAutoSave()
    }
  }

  summaryAutoSave = summary => {
    if (this.state.isFormInitialized) {
      this.draftArticle = {
        ...this.draftArticle,
        summary,
      }
      this.debouncedAutoSave()
    }
  }

  descriptionAutoSave = description => {
    if (this.state.isFormInitialized) {
      this.draftArticle = {
        ...this.draftArticle,
        description,
      }
      this.debouncedAutoSave()
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  isWarningPublishDatetime = () => {
    const { articleStatus, publishDatetime: currentPublishDatetime } = this.state
    const { publishDatetime: initialPublishDatetime } = this.initialFormValues

    return (
      _.isEqual(articleStatus, ArticleStatus.PUBLISH) &&
      (_.isEmpty(currentPublishDatetime) ||
        (!_.isEmpty(initialPublishDatetime) &&
          moment(initialPublishDatetime).isSameOrBefore(moment(), 'minute') &&
          moment(currentPublishDatetime).isAfter(moment(), 'minute')))
    )
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    if (this.isWarningPublishDatetime() && !this.state.isWarningModalOpen) {
      this.setState({
        warningModalMessage: _.isEmpty(this.state.publishDatetime)
          ? "公開済の記事です。日時設定の削除により<span style='color: red'>記事が非公開と</span>なります。"
          : "公開済の記事です。日時設定の変更により<span style='color: red'>公開予約ステータスに変更</span>されます。",
        isWarningModalOpen: true,
      })
      return
    }

    // eslint-disable-next-line no-unreachable
    this.debouncedTitleAutoSave.cancel()
    this.debouncedSummaryAutoSave.cancel()
    this.debouncedDescriptionAutoSave.cancel()
    this.debouncedAutoSave.cancel()

    this.setState(
      {
        isBusy: true,
        isDuringSubmit: true,
        apiError: null,
        isFormInitialized: false,
        isWarningModalOpen: false,
      },
      () => {
        this.getRequestParameters({ ...submitFormData, summary: this.state.summary })
          .then(requestParameters => {
            patchArticle = CancelablePromise(this.sendArticleData(requestParameters))
            return patchArticle.promise
          })
          .then(response => {
            const article = response.data
            const { title, description, publishDatetime, medium, creator, series } = article
            const isDisableArticlePreviewButton = !(title && description)
            const articleImageData = {}

            // Update article thumbnail and cover image
            if (this.state.isThumbnailImageModified && !this.state.isCoverImageModified) {
              articleImageData.thumbnailImageUrlInputValue = _.get(article, 'thumbnail.image.url', '')
              articleImageData.coverImageUrlInputValue = _.get(article, 'cover.image.url', '')
            }

            logger.debug(`update article id #${article.id} completed response`, response)

            // 記事の下書きキー一覧データ
            const draftKeys = article.draftKeys
            // 記事のプレビューキー
            const previewKey = _.get(article, 'originalArticle.previewKey', '')

            this.setState({
              ...articleImageData,
              publishDatetime: convertTextToDatetime(publishDatetime),
              draftKeys,
              previewKey,
              isDisableArticlePreviewButton,
              medium,
              creator,
              series,
            })

            // フォームデータを更新して初期化
            this.initializeFormValues(article)

            resetForm(this.initialFormValues)

            this.setState({ descriptionInputValue: description })

            // 記事に紐づくタグの登録と削除を行う
            return this.sendArticleTagIdsData(article.id)
          })
          .then(articleId => {
            logger.debug(`handle form valid submit success data update article id ${articleId}`)

            this.setState(
              {
                isFormModified: false,
                isSummaryModified: false,
                isTitleTagModified: false,
                isDescriptionTagModified: false,
                isThumbnailImageModified: false,
                isCoverImageModified: false,
                isDuringSubmit: false,
                isBusy: false,
                isFormInitialized: true,
                editorWarning: false,
              },
              () => {
                if (!_.isNil(articleId)) {
                  // ルーティングを更新（記事の作成から記事の編集表示に切り替わるため）
                  this.props.router.replace(`/article/${articleId}`)
                }
              },
            )
          })
          .catch(error => {
            if (error.isCanceled) {
              return
            }

            logger.error('update article article error ', error)

            this.setState({
              isBusy: false,
              isDuringSubmit: false,
              apiError: error,
              isFormInitialized: true,
            })
          })
      },
    )
  }

  handleWarningModalClose = () => {
    this.setState({
      isWarningModalOpen: false,
    })
  }

  handleSubmitButtonWarningModalClick = () => {
    if (!this.isWarningPublishDatetime()) {
      return
    }

    const handleSubmitForm = () => this.form.submit()

    if (_.isEmpty(this.state.publishDatetime)) {
      this.setState({ articleStatus: ArticleStatus.PENDING }, handleSubmitForm)
    } else {
      handleSubmitForm()
    }
  }

  /**
   * 記事本文エディターの初期化時のハンドラ
   */
  handleDescriptionEditorInit = ({ editor }) => {
    this.descriptionEditor = editor
  }

  /**
   * 記事本文エディターの内容が変わったときのハンドラ
   */
  handleDescriptionEditorChange = ({ description, error }) => {
    logger.debug('handle description editor change', description)

    const partialState = {
      descriptionInputValue: _.defaultTo(description, ''),
      descriptionError: error,
    }

    this.setState(partialState)
  }

  handleEditorWarning = display => {
    this.setState({ editorWarning: display })
  }

  handleSidebarMenuItemClick = (event, { name }) => {
    this.setState({ viewSidebarStatus: name })
  }

  /**
   * Handler for publish datetime (by input or by pick) changing event
   */
  handlePublishDatetimeChange = (event, { value }) => {
    this.setState({ publishDatetime: value })
  }

  /**
   * Handler for 'Set Today' button clicking event
   */
  handlePublishDatetimeSetToday = () => {
    const getNextTime = () => {
      const today = moment()
      const hour = today.minutes() >= 35 ? today.hours() + 1 : today.hours()

      return `${hour}:35:00`
    }

    const date = moment().format('YYYY-MM-DD')
    const time = _.isEmpty(this.state.publishDatetime)
      ? getNextTime()
      : moment(this.state.publishDatetime).format('HH:mm:ss')

    const publishDatetime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss')
    this.setState({ publishDatetime })
  }

  /**
   * スポンサー選択ドロップダウンの値を変更したときのハンドラ
   */
  handleSponsorDropdownChange = (event, { value }) => {
    if (_.isNil(this.state.currentFormValues.sponsorId) && !_.isNil(this.state.currentFormValues.isAffiliated)) {
      const valuesNeedToReset = { isAffiliated: false }

      this.form.reset(_.assign({}, this.state.currentFormValues, valuesNeedToReset))
    }

    this.setState({ sponsorId: value })
  }

  /**
   * メディア選択ドロップダウンの値を変更したときのハンドラ
   */
  handleMediaDropdownChange = (event, { value, detail }) => {
    const valuesNeedToReset = {}
    const statesNeedToReset = { medium: detail, mediumId: value }

    if (!_.isNil(this.state.currentFormValues.mediumItemId)) {
      valuesNeedToReset.mediumItemId = null
      statesNeedToReset.mediumItemId = null
    }

    if (!_.isNil(this.state.currentFormValues.creatorId)) {
      valuesNeedToReset.creatorId = null
      statesNeedToReset.creator = null
    }

    if (!_.isNil(this.state.currentFormValues.seriesId)) {
      valuesNeedToReset.seriesId = null
      statesNeedToReset.series = null
    }

    const managementCategoryId = _.get(detail, 'managementCategory.id', null)
    if (managementCategoryId) {
      valuesNeedToReset.managementCategoryId = managementCategoryId
      statesNeedToReset.managementCategoryId = managementCategoryId
    } else if (_.isEqual(_.get(this.state.medium, 'managementCategory.id'), this.state.managementCategoryId)) {
      valuesNeedToReset.managementCategoryId = null
      statesNeedToReset.managementCategoryId = null
    }

    if (!_.isEmpty(valuesNeedToReset)) {
      this.form.reset(_.assign({}, this.state.currentFormValues, valuesNeedToReset))
    }

    this.setState(statesNeedToReset)
  }

  /**
   * メディアの設定選択ドロップダウンの値を変更したときのハンドラ
   */
  handleMediumItemDropdownChange = (event, { value }) => {
    this.setState({ mediumItemId: value })
  }

  /**
   * Handler to be called when the dropdown creator selection value is changed
   */
  handleCreatorDropdownChange = (event, { detail }) => {
    const valuesNeedToReset = {}

    if (!_.isNil(this.state.currentFormValues.seriesId)) {
      valuesNeedToReset.seriesId = null
    }

    if (!_.isEmpty(valuesNeedToReset)) {
      this.form.reset(_.assign({}, this.state.currentFormValues, valuesNeedToReset))
    }

    this.setState({ creator: detail, series: null })
  }

  /**
   * Handler to be called when the dropdown series selection value is changed
   */
  handleSeriesDropdownChange = (event, { detail }) => {
    this.setState({ series: detail })
  }

  /**
   * カテゴリ選択ドロップダウンの値を変更したときのハンドラ
   */
  handleCategoriesDropdownChange = (event, { value }) => {
    this.setState({ categoryId: value })
  }

  /**
   * 管理用カテゴリ選択ドロップダウンの値を変更したときのハンドラ
   */
  handleManagementCategoriesDropdownChange = (event, { value }) => {
    this.setState({ managementCategoryId: value })
  }

  /**
   * 記事のタイトルに関連するタグを変更したときのハンドラ
   */
  handleTitleTagsDropdownChange = (event, { value }) => {
    this.setState({
      titleTagsDropdownValue: value,
      isTitleTagModified: !_.isEqual(value, this.initialTitleTagIds),
    })
  }

  /**
   * 記事の本文に関連するタグを変更したときのハンドラ
   */
  handleDescriptionTagsDropdownChange = (event, { value }) => {
    this.setState({
      descriptionTagsDropdownValue: value,
      isDescriptionTagModified: !_.isEqual(value, this.initialDescriptionTagIds),
    })
  }

  /**
   * サムネイル画像を変更したときのハンドラ
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    const value = _.defaultTo(mediumUrl, '')

    this.setState({
      thumbnailImageUrlInputValue: value,
      isThumbnailImageModified: !_.isEqual(value, this.initialFormValues['thumbnail.image.url']),
      thumbnailImageError: error,
    })
  }

  /**
   * カバー画像を変更したときのハンドラ
   */
  handleCoverImageInputChange = (event, { mediumUrl, error }) => {
    const value = _.defaultTo(mediumUrl, '')

    this.setState({
      coverImageUrlInputValue: value,
      isCoverImageModified: !_.isEqual(value, this.initialFormValues['cover.image.url']),
      coverImageError: error,
    })
  }

  /**
   * カバー画像をサムネイル画像とは別のものに設定をするかどうかのトグルを変更したときのハンドラ
   */
  handleCoverImageToggleChange = (event, { checked }) => {
    this.setState({ isCoverImageActive: checked })
  }

  /**
   * メディア関連記事を変更したときのハンドラ
   */
  handleMediumRelatedArticlesChange = ({ articles }) => {
    this.setState({ mediumRelatedArticlesInputValue: articles })
  }

  /**
   * プレビューキーを変更したときのハンドラ
   */
  handlePreviewKeyChange = (event, { value }) => {
    // DB に設定されている previewKey と value が違う かつ draftKeys の中に value が存在する
    const isConflictDraftKey = this.state.previewKey !== value && _.indexOf(this.state.draftKeys, value) !== -1

    // 状態変更
    this.setState({ isConflictDraftKey })
  }

  handleSummaryChange = summary => {
    let isSummaryModified = false

    if (!_.isEqual(this.initialFormValues.summary, summary)) {
      isSummaryModified = true
    }

    if (this.state.isNew) {
      const isSummaryValid = !_.isEmpty(summary)

      if (this.state.isSummaryValid !== isSummaryValid) {
        this.setState({ isSummaryValid })
      }
    } else if (!this.state.isNew && !this.state.isSummaryValid) {
      this.setState({ isSummaryValid: true })
    }

    this.setState({ summary, isSummaryModified })
    this.debouncedSummaryAutoSave(summary)
  }

  /**
   * 公開中 or 保留 or 下書き or ゴミ箱の状態変更メニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreArticle = CancelablePromise(this.restoreArticle(this.state.articleId))
    restoreArticle.promise
      .then(() => {
        // ゴミ箱の場合は削除
        if (_.isEqual(name, ArticleStatus.TRASH)) {
          return articleApi.deleteArticle(this.state.articleId)
        }

        const articleUpdateValues = { status: name }
        return articleApi.patchArticle(this.state.articleId, {
          articleUpdateValues,
        })
      })
      .then(response => {
        const article = response.data
        if (!_.isEmpty(article)) {
          const { isFormModified, isSummaryModified } = this.state
          const { status, publishDatetime } = article

          // フォームデータを更新して初期化
          this.initializeFormValues(article)

          this.setState(
            {
              articleStatus: status,
              publishDatetime: convertTextToDatetime(publishDatetime),
              isBusy: false,
            },
            () => {
              this.setState({ isFormModified, isSummaryModified })
            },
          )
        } else {
          this.setState({
            articleStatus: name,
            isBusy: false,
          })
        }
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('change article status error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 記事の状態が削除であれば削除から記事を戻す
   */
  restoreArticle = articleId =>
    new Promise((resolve, reject) => {
      if (!_.isEqual(this.state.articleStatus, ArticleStatus.TRASH)) {
        resolve()

        return
      }

      articleApi
        .putArticle(articleId)
        .then(() => {
          resolve()
        })
        .catch(error => {
          logger.error(`put article articleId #${articleId} error`, error)

          reject(error)
        })
    })

  /**
   * API にデータを送信
   */
  sendArticleData(submitArticleData) {
    const articleId = this.state.articleId
    return new Promise((resolve, reject) => {
      if (_.isEmpty(submitArticleData)) {
        const error = new Error('Missing required article data')
        reject(error)
      }

      logger.debug(`update article #${articleId}`, { submitArticleData })

      const articleUpdateValues = submitArticleData
      return articleApi
        .patchArticle(articleId, { articleUpdateValues })
        .then(response => {
          resolve(response)
          this.setState({
            draftExists: false,
            isNew: false,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * 記事一覧取得時に保持したタイトルに紐づくタグ一覧からドロップダウンのタグ一覧の差分を取得
   * 差分がある場合は取得した ID 一覧の保存・削除を行う
   * @param {string} articleId 記事 ID
   */
  sendArticleTagIdsData(articleId) {
    return new Promise((resolve, reject) => {
      // 初回表示時のタグ一覧
      const initialTitleTagIds = this.initialTitleTagIds
      const initialDescriptionTagIds = this.initialDescriptionTagIds

      // 変更されたタグ一覧
      const changedTitleTagIds = this.state.titleTagsDropdownValue
      const changedDescriptionTagIds = this.state.descriptionTagsDropdownValue

      // 追加するタグ一覧
      const addTitleTagIds = _.difference(changedTitleTagIds, initialTitleTagIds)
      const addDescriptionTagIds = _.difference(changedDescriptionTagIds, initialDescriptionTagIds)

      // 削除するタグ一覧
      const deleteTitleTagIds = _.difference(initialTitleTagIds, changedTitleTagIds)
      const deleteDescriptionTagIds = _.difference(initialDescriptionTagIds, changedDescriptionTagIds)

      logger.debug('send artcicle tags data', {
        addTitleTagIds,
        deleteTitleTagIds,
        addDescriptionTagIds,
        deleteDescriptionTagIds,
      })

      if (
        _.isEmpty(addTitleTagIds) &&
        _.isEmpty(deleteTitleTagIds) &&
        _.isEmpty(addDescriptionTagIds) &&
        _.isEmpty(deleteDescriptionTagIds)
      ) {
        // 追加も削除もなければ、API 通信を行わずに記事 ID を返却
        resolve(articleId)
      }

      // API 通信を行う
      const putTitleTagPromises = _.map(addTitleTagIds, tagId => articleApi.putArticleTitleTag(articleId, tagId))
      const putDescriptionTagPromises = _.map(addDescriptionTagIds, tagId =>
        articleApi.putArticleDescriptionTag(articleId, tagId),
      )
      const deleteTitleTagPromises = _.map(deleteTitleTagIds, tagId =>
        articleApi.deleteArticleTitleTag(articleId, tagId),
      )
      const deleteDescriptionTagPromises = _.map(deleteDescriptionTagIds, tagId =>
        articleApi.deleteArticleDescriptionTag(articleId, tagId),
      )
      Promise.all([
        putTitleTagPromises,
        putDescriptionTagPromises,
        deleteTitleTagPromises,
        deleteDescriptionTagPromises,
      ])
        .then(() => {
          this.initialTitleTagIds = this.state.titleTagsDropdownValue
          this.initialDescriptionTagIds = this.state.descriptionTagsDropdownValue

          resolve(articleId)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * 記事ドラフト取得
   * @param {string} articleId
   */
  retrieveDraft = async articleId => {
    let draftResponse = {}

    try {
      const draftArticle = await draftApi.getDraftArticle(articleId)
      draftResponse = draftArticle.data
      logger.debug('GET draft API called successfully. Returned response: ', draftResponse)
      this.setState({ draftExists: true })
    } catch (error) {
      logger.debug(error)
      this.setState({ draftExists: false })
    }

    this.draftArticle = {
      title: draftResponse.title,
      summary: draftResponse.summary,
      description: draftResponse.description,
    }

    this.setState({
      summary: draftResponse.summary,
    })
  }

  /**
   * 記事IDから取得したタグを現在のタグ設定フィールドに結合する
   * @param {string} articleId - 記事 id
   * @param {bool} isCopyTitle - 記事のタイトルに関連するタグを結合するかどうか
   * @param {bool} isCopyDescription - 事の本文に関連するタグを結合するかどうか
   */
  mergeTagsByArticleId = async (articleId, isCopyTitle, isCopyDescription) => {
    const allArticleTagsPromise = []
    if (isCopyTitle) allArticleTagsPromise.push(this.mergeTitleTagsByArticleId(articleId))
    if (isCopyDescription) allArticleTagsPromise.push(this.mergeDescriptionTagsByArticleId(articleId))
    await Promise.all(allArticleTagsPromise)
  }

  /**
   * 記事IDから取得した記事のタイトルに関連するタグを現在のタイトルに関連するタグ設定フィールドに結合する
   * @param {string} articleId - 記事 id
   */
  mergeTitleTagsByArticleId = async articleId => {
    const titleTags = await this.getAllArticleTags(articleId, 'title')
    const { titleTagsDropdownValue } = this.state

    // 記事のタイトルに関連するタグ設定フィールドと結合
    const mergedTitleTagsDropdownValue = _.union(
      titleTagsDropdownValue,
      _.map(titleTags, titleTag => titleTag.id),
    )

    this.setState({
      titleTagsDropdownValue: mergedTitleTagsDropdownValue,
      isTitleTagModified: !_.isEqual(mergedTitleTagsDropdownValue, this.initialTitleTagIds),
    })
  }

  /**
   * 記事IDから取得した記事の本文に関連するタグを現在の本文に関連するタグ設定フィールドに結合する
   * @param {string} articleId - 記事 id
   */
  mergeDescriptionTagsByArticleId = async articleId => {
    const descriptionTags = await this.getAllArticleTags(articleId, 'description')
    const { descriptionTagsDropdownValue } = this.state

    // 記事の本文に関連するタグ設定フィールドと結合
    const mergedDescriptionTagsDropdownValue = _.union(
      descriptionTagsDropdownValue,
      _.map(descriptionTags, descriptionTag => descriptionTag.id),
    )

    this.setState({
      descriptionTagsDropdownValue: mergedDescriptionTagsDropdownValue,
      isDescriptionTagModified: !_.isEqual(mergedDescriptionTagsDropdownValue, this.initialDescriptionTagIds),
    })
  }

  /**
   * 記事取得
   * @param {string} articleId - 記事 id
   */
  async retrieveArticle(articleId, fromOutsourcedArticle = false) {
    logger.debug(`retrieve article #${articleId}`)

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getArticle = CancelablePromise(articleApi.getArticle(articleId))
    // 記事, 記事本文に関連するタグ, 記事タイトルに関連するタグを取得
    try {
      const [articleResponse, descriptionTags, titleTags] = await Promise.all([
        getArticle.promise,
        this.getAllArticleTags(articleId, 'description'),
        this.getAllArticleTags(articleId, 'title'),
      ])

      logger.debug(`retrieved article #${articleId} data`, {
        articleResponse,
        descriptionTags,
        titleTags,
      })

      // 記事詳細データ
      const articleData = articleResponse.data
      // 記事のタイトルに関連するタグ一覧データ
      const titleTagsData = titleTags
      // 記事の本文に関連するタグ一覧データ
      const descriptionTagsData = descriptionTags
      // 記事のステータス (deleted_at が あった場合は TRASH に設定)
      const articleStatus = _.isEmpty(articleData.deletedAt)
        ? _.defaultTo(articleData.status, ArticleStatus.PUBLISH)
        : ArticleStatus.TRASH
      // 記事の下書きキー一覧データ
      const draftKeys = articleData.draftKeys
      // 記事のプレビューキー
      const previewKey = _.get(articleData, 'originalArticle.previewKey', '')
      const originalArticleLink = _.get(articleData, 'originalArticle.link', '')
      // 編集委託記事ID
      const outsourcedArticleId = _.get(articleData, 'outsourcedArticle.id', '')
      const isDisableArticlePreviewButton = !(_.get(articleData, 'title', '') && _.get(articleData, 'description', ''))

      this.initializeFormValues(articleData)

      // フォーム入力データを初期化
      this.form.reset(this.initialFormValues)

      const newFormValues = {}
      const newStateValues = {}

      if (fromOutsourcedArticle) {
        const summary = getOutsourceArticleValue(articleData, 'summary')

        const thumbnailImageUrl = getOutsourceArticleValue(articleData, 'thumbnail.image.url')
        const coverImageUrl = getOutsourceArticleValue(articleData, 'cover.image.url')

        const publishDatetime = getOutsourceArticleValue(articleData, 'publishDatetime')

        newFormValues.title = getOutsourceArticleValue(articleData, 'title')
        newFormValues.summary = summary
        newFormValues['thumbnail.image.url'] = thumbnailImageUrl
        newFormValues['thumbnail.image.copyright.title'] = getOutsourceArticleValue(
          articleData,
          'thumbnail.image.copyright.title',
        )
        newFormValues['thumbnail.image.copyright.url'] = getOutsourceArticleValue(
          articleData,
          'thumbnail.image.copyright.url',
        )
        newFormValues['cover.image.url'] = getOutsourceArticleValue(articleData, 'cover.image.url')
        newFormValues['cover.image.copyright.title'] = getOutsourceArticleValue(
          articleData,
          'cover.image.copyright.title',
        )
        newFormValues['cover.image.copyright.url'] = getOutsourceArticleValue(articleData, 'cover.image.copyright.url')

        newFormValues.publishDatetime = publishDatetime

        newStateValues.summary = summary
        newStateValues.descriptionInputValue = getOutsourceArticleValue(articleData, 'description')
        newStateValues.thumbnailImageUrlInputValue = thumbnailImageUrl
        newStateValues.isCoverImageActive = !_.isEmpty(coverImageUrl) && coverImageUrl !== thumbnailImageUrl
        newStateValues.coverImageUrlInputValue = coverImageUrl

        newStateValues.publishDatetime = convertTextToDatetime(publishDatetime)
      } else {
        if (this.state.draftExists) {
          const summary = this.draftArticle.summary

          newFormValues.title = this.draftArticle.title
          newFormValues.summary = summary

          newStateValues.summary = summary
          newStateValues.descriptionInputValue = this.draftArticle.description
        }

        const thumbnailImageUrl = this.initialFormValues['thumbnail.image.url']
        const coverImageUrl = this.initialFormValues['cover.image.url']

        newStateValues.summary = this.initialFormValues.summary
        newStateValues.descriptionInputValue = this.initialFormValues.description
        newStateValues.thumbnailImageUrlInputValue = thumbnailImageUrl
        newStateValues.isCoverImageActive = !_.isEmpty(coverImageUrl) && coverImageUrl !== thumbnailImageUrl
        newStateValues.coverImageUrlInputValue = coverImageUrl

        newStateValues.publishDatetime = convertTextToDatetime(articleData.publishDatetime)
      }

      // 記事のタイトルに関連するタグ設定フィールドの選択のオプションを初期化
      const titleTagsDropdownValue = _.map(titleTagsData, titleTagData => titleTagData.id)
      // タグの登録・削除時に差分を算出するために取得時のタイトルに紐づくタグ ID を保持
      this.initialTitleTagIds = titleTagsDropdownValue

      // タグ設定フィールドの選択のオプションを初期化
      const descriptionTagsDropdownValue = _.map(descriptionTagsData, descriptionTagData => descriptionTagData.id)
      // タグの登録・削除時に差分を算出するために取得時の本文に紐づくタグ ID を保持
      this.initialDescriptionTagIds = descriptionTagsDropdownValue

      // メディア関連記事を初期化
      const mediumRelatedArticles = articleData.mediumRelatedArticles
      const mediumRelatedArticlesInputValue = this.initialFormValues.mediumRelatedArticles

      // Initialize medium configuration
      const medium = _.get(articleData, 'medium', null)
      // メディア ID を初期化
      const mediumId = this.initialFormValues.mediumId
      // メディアの設定 ID を初期化
      const mediumItemId = this.initialFormValues.mediumItemId
      // Initialize creator configuration
      const creator = _.get(articleData, 'creator', null)
      // Initialize series configuration
      const series = _.get(articleData, 'series', null)
      // スポンサー ID を初期化
      const sponsorId = this.initialFormValues.sponsorId
      // Initialize isAffiliated configuration
      const isAffiliated = this.initialFormValues.isAffiliated
      // Initialize isShowPinterestRssLink configuration
      const isShowPinterestRssLink = this.initialFormValues.isPinterestAutoPublish
      // カテゴリ ID を初期化
      const categoryId = this.initialFormValues.categoryId
      // 管理用カテゴリ ID を初期化
      const managementCategoryId = this.initialFormValues.managementCategoryId

      if (!_.isEmpty(newFormValues)) {
        this.form.reset(_.assign({}, this.initialFormValues, newFormValues))
      }

      this.setState({
        ...newStateValues,

        articleStatus,
        draftKeys,
        previewKey,

        titleTagsDropdownValue,
        descriptionTagsDropdownValue,

        mediumRelatedArticles,
        mediumRelatedArticlesInputValue,

        medium,
        mediumId,
        mediumItemId,
        creator,
        series,
        sponsorId,
        isAffiliated,
        isShowPinterestRssLink,
        categoryId,
        managementCategoryId,

        originalArticleLink,
        outsourcedArticleId,
        isDisableArticlePreviewButton,

        isBusy: false,
      })
    } catch (errors) {
      if (errors.isCanceled) {
        return
      }

      logger.error(`retrieve article #${articleId} error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  /**
   * フォームの初期化
   * @param {Object} articleData - 記事データ
   */
  initializeFormValues(articleData) {
    this.initialFormValues.title = articleData.title
    this.initialFormValues.slug = articleData.slug
    this.initialFormValues.summary = articleData.summary
    this.initialFormValues.categoryId = _.get(articleData, 'category.id', null)
    this.initialFormValues.managementCategoryId = _.get(articleData, 'managementCategory.id', null)
    this.initialFormValues.mediumId = _.get(articleData, 'medium.id', null)
    this.initialFormValues.mediumItemId = _.get(articleData, 'mediumItem.id', null)
    this.initialFormValues.creatorId = _.get(articleData, 'creator.id', null)
    this.initialFormValues.seriesId = _.get(articleData, 'series.id', null)
    this.initialFormValues.isAffiliated = articleData.isAffiliated
    this.initialFormValues.isPinterestAutoPublish = articleData.isPinterestAutoPublish
    this.initialFormValues.sponsorId = _.get(articleData, 'sponsor.id', null)
    // isFormModified 判定で使用するため、公開日時を表示する形式に変換
    const publishDatetime = articleData.publishDatetime
    this.initialFormValues.publishDatetime = _.isEmpty(publishDatetime) ? '' : moment(publishDatetime).format()

    this.initialFormValues['thumbnail.image.url'] = _.get(articleData, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(
      articleData,
      'thumbnail.image.copyright.title',
      '',
    )
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(articleData, 'thumbnail.image.copyright.url', '')

    this.initialFormValues.description = _.defaultTo(articleData.description, '')

    this.initialFormValues['cover.image.url'] = _.get(articleData, 'cover.image.url', '')
    this.initialFormValues['cover.image.copyright.title'] = _.get(articleData, 'cover.image.copyright.title', '')
    this.initialFormValues['cover.image.copyright.url'] = _.get(articleData, 'cover.image.copyright.url', '')

    this.initialFormValues.mediumRelatedArticles = articleData.mediumRelatedArticles

    // プレビュー時に必要となる previewKey
    this.initialFormValues['originalArticle.previewKey'] = _.get(articleData, 'originalArticle.previewKey', '')

    this.setState({ summary: articleData.summary })

    logger.debug('initial form values', this.initialFormValues, articleData)
  }

  /**
   * 記事タイトルに関連するタグを全件取得
   * @param {number} articleId - 記事 id
   * @param {string} type - 取得するタグのタイプ title or description
   */
  getAllArticleTags = (articleId, type) =>
    new Promise((resolve, reject) => {
      const header = {
        currentPage: 1,
        itemsPerPage: 10,
      }

      // 記事本文に関連するタグの配列
      const articleTags = []

      // タイプで分ける
      const getArticleTagsApi = (_articleId, _header) => {
        if (_.isEqual(type, 'title')) {
          return articleApi.getArticleTitleTags(_articleId, _header)
        }

        return articleApi.getArticleDescriptionTags(_articleId, _header)
      }

      getArticleTagsApi(articleId, header)
        .then(response => {
          // 全ページ分の通信を入れる Promise
          const articleTagsPromises = []
          articleTags.push(response.data)

          // 合計ページ数
          const totalPages = _.get(response, 'header.pagination-totalpages', 0)

          // 現在ページが合計ページ未満の場合はループ
          while (header.currentPage < totalPages) {
            // 次のページ取得のため +1 する
            header.currentPage += 1
            articleTagsPromises.push(getArticleTagsApi(articleId, header))
          }

          return Promise.all(articleTagsPromises)
        })
        .then(responses => {
          _.each(responses, response => articleTags.push(response.data))

          // 多重配列を戻してから resolve
          resolve(_.flatten(articleTags))
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData =>
    new Promise((resolve, reject) => {
      // 変更前のフォームの値
      const initialFormValues = this.initialFormValues
      const initialData = {}
      initialData.title = initialFormValues.title
      initialData.slug = initialFormValues.slug
      initialData.summary = initialFormValues.summary
      initialData.mediumId = _.get(initialFormValues, 'mediumId', null)
      initialData.mediumItemId = _.get(initialFormValues, 'mediumItemId', null)
      initialData.categoryId = _.get(initialFormValues, 'categoryId', null)
      initialData.managementCategoryId = _.get(initialFormValues, 'managementCategoryId', null)
      initialData.creatorId = _.get(initialFormValues, 'creatorId', null)
      initialData.seriesId = _.get(initialFormValues, 'seriesId', null)
      initialData.isAffiliated = initialFormValues.isAffiliated
      initialData.isPinterestAutoPublish = initialFormValues.isPinterestAutoPublish
      initialData.sponsorId = _.get(initialFormValues, 'sponsorId', null)
      initialData.description = _.defaultTo(initialFormValues.description, '')
      initialData.mediumRelatedArticles = initialFormValues.mediumRelatedArticles
      initialData.publishDatetime = _.get(initialFormValues, 'publishDatetime', '')

      // フォームに入っている値がドット記法のためオブジェクトに変換
      // 画像 url のみ default 値として state として空文字を設定しているため default 値として '' を指定
      _.set(initialData, 'thumbnail.image.url', _.get(initialFormValues, 'thumbnail.image.url', ''))
      _.set(initialData, 'thumbnail.image.copyright.title', _.get(initialFormValues, 'thumbnail.image.copyright.title'))
      _.set(initialData, 'thumbnail.image.copyright.url', _.get(initialFormValues, 'thumbnail.image.copyright.url'))

      _.set(initialData, 'cover.image.url', _.get(initialFormValues, 'cover.image.url', ''))
      _.set(initialData, 'cover.image.copyright.title', _.get(initialFormValues, 'cover.image.copyright.title'))
      _.set(initialData, 'cover.image.copyright.url', _.get(initialFormValues, 'cover.image.copyright.url'))

      _.set(initialData, 'originalArticle.previewKey', _.get(initialFormValues, 'originalArticle.previewKey', ''))

      TrillDescription.parse(this.descriptionEditor.getContent())
        .then(trillDescription => {
          // 判定用のためにフォームに入れている値は送らない
          _.unset(submitFormData, 'options')

          //  カバー画像を設定しない場合はサムネイル画像と同じものを送る
          const isSetCoverAsTheSameThumbnailImage =
            !this.state.isCoverImageActive || (this.state.isThumbnailImageModified && !this.state.isCoverImageModified)
          if (isSetCoverAsTheSameThumbnailImage) {
            submitFormData.cover.image = submitFormData.thumbnail.image
          }

          // フォームから送られてきたデータと初期データの差分を取得 (空のデータは送らない)
          const requestParameters = differenceObject(submitFormData, initialData)

          // 記事本文
          if (_.has(requestParameters, 'description')) {
            // 本文に変更があれば TRILL 仕様書に合わせたものに変更した本文を設定
            requestParameters.description = trillDescription.toHtmlString()
          }

          // 関連記事があれば全て送る
          if (_.has(requestParameters, 'mediumRelatedArticles')) {
            requestParameters.mediumRelatedArticles = submitFormData.mediumRelatedArticles
          }

          // status は必須項目のため設定 (新規作成の場合は下書きとして保存のため draft に設定)
          requestParameters.status = _.defaultTo(this.state.articleStatus, ArticleStatus.DRAFT)

          // 上記理由のため API に送る値は this.state.publishDatetime の値を RFC 3339 形式 に設定
          if (_.has(requestParameters, 'publishDatetime') && _.isEmpty(requestParameters.publishDatetime)) {
            // 公開日時の設定が空文字の場合はリセット
            requestParameters.publishDatetime = ' '
          } else if (_.has(requestParameters, 'publishDatetime')) {
            requestParameters.publishDatetime = this.state.publishDatetime.toISOString()
          }

          // slug に空文字に設定されていた場合はリセット
          if (_.isEqual(requestParameters.slug, '')) {
            requestParameters.slug = ' '
          }

          // sponsorId に null が入っていたら 0 を送ってリセット
          if (_.has(requestParameters, 'sponsorId') && _.isNil(requestParameters.sponsorId)) {
            requestParameters.sponsorId = 0
          }

          // categoryId に null が入っていたら 0 を送ってリセット
          if (_.has(requestParameters, 'categoryId') && _.isNil(requestParameters.categoryId)) {
            requestParameters.categoryId = 0
          }

          // managementCategoryId に null が入っていたら 0 を送ってリセット
          if (_.has(requestParameters, 'managementCategoryId') && _.isNil(requestParameters.managementCategoryId)) {
            requestParameters.managementCategoryId = 0
          }

          // If creatorId contains null, send 0 to reset
          if (_.has(requestParameters, 'creatorId') && _.isNil(requestParameters.creatorId)) {
            requestParameters.creatorId = 0
          }

          // If seriesId contains null, send 0 to reset
          if (_.has(requestParameters, 'seriesId') && _.isNil(requestParameters.seriesId)) {
            requestParameters.seriesId = 0
          }

          // サムネイル画像の出典元に空文字が設定されていた場合はリセット
          if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.title'), '')) {
            _.set(requestParameters, 'thumbnail.image.copyright.title', ' ')
          }

          // サムネイル画像の出典元の URL に空文字が設定されていた場合はリセット
          if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.url'), '')) {
            _.set(requestParameters, 'thumbnail.image.copyright.url', ' ')
          }

          // カバー画像の出典元に空文字が設定されていた場合はリセット
          if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.title'), '')) {
            _.set(requestParameters, 'cover.image.copyright.title', ' ')
          }

          // カバー画像の出典元の URL に空文字が設定されていた場合はリセット
          if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.url'), '')) {
            _.set(requestParameters, 'cover.image.copyright.url', ' ')
          }

          _.merge(requestParameters, {
            thumbnail: {
              video: {
                url: ' ',
                copyright: {
                  title: ' ',
                  url: ' ',
                },
              },
            },
            cover: {
              video: {
                url: ' ',
                copyright: {
                  title: ' ',
                  url: ' ',
                },
              },
            },
          })

          // 画像のURLからリクエストを送信する前に、クエリパラメータ（例：?temp）を削除してください。
          const handleRemoveQueryParamsInUrl = key => {
            const url = _.get(requestParameters, key, '')

            if (url.match(/^(http:|https:|).*\?/gm)) {
              _.set(requestParameters, key, url.split('?')[0])
            }
          }
          _.each(['thumbnail', 'cover'], type => {
            _.each(['url', 'copyright.url'], key => handleRemoveQueryParamsInUrl([type, 'image', key].join('.')))
          })

          // PreviewKey に空文字に設定されていた場合はリセット
          if (_.isEqual(_.get(requestParameters, 'originalArticle.previewKey'), '')) {
            requestParameters.previewKey = ' '
          }

          logger.debug('get request parameters', {
            initialData,
            submitFormData,
            requestParameters,
          })

          resolve(_.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && !_.isArray && _.isEmpty(v)))
        })
        .catch(error => {
          let message
          if (error.message === 'Invalid image display size: {}') {
            message =
              '何らかの理由で画像の表示サイズを取得できませんでした。Slackの#tl-help-cmsチャンネルに連絡いただくかお近くのエンジニアに問題の解決をご依頼ください。'
          } else if (
            message === 'Invalid block dom detected: {"type":"tag","name":"figure","attribs":{"class":"image"}}'
          ) {
            message =
              '画像の出典が残っている箇所があります。5分探しても見つからない場合はSlackの#tl-help-cmsチャンネルにご連絡いただくか、お近くのエンジニアに出典の捜索をご依頼ください。'
          } else {
            message =
              '記事本文内のHTML構成にエラーがあります。Slackの#tl-help-cmsチャンネルにご連絡いただくか、お近くのエンジニアに問題の解決をご依頼ください。'
            message = `${message} ${error.message}`
          }
          error.message = message
          reject(error)
        })
    })

  render() {
    return (
      <Article
        {...this.state}
        setRef={elem => {
          this.form = elem
        }}
        initialFormValues={this.initialFormValues}
        fromOutsourcedArticle={_.get(this.props, 'location.query.fromOutsourcedArticle') === 'true'}
        autoSave={this.autoSave}
        revertChange={this.revertChange}
        handleFormChange={this.handleFormChange}
        handleFormValid={this.handleFormValid}
        handleFormInvalid={this.handleFormInvalid}
        handleFormValidSubmit={this.handleFormValidSubmit}
        handleDescriptionEditorInit={this.handleDescriptionEditorInit}
        handleDescriptionEditorChange={this.handleDescriptionEditorChange}
        handlePublishDatetimeChange={this.handlePublishDatetimeChange}
        handlePublishDatetimeInvalid={this.handleFormInvalid}
        handlePublishDatetimeSetToday={this.handlePublishDatetimeSetToday}
        handleSponsorDropdownChange={this.handleSponsorDropdownChange}
        handleMediaDropdownChange={this.handleMediaDropdownChange}
        handleMediumItemDropdownChange={this.handleMediumItemDropdownChange}
        handleCreatorDropdownChange={this.handleCreatorDropdownChange}
        handleSeriesDropdownChange={this.handleSeriesDropdownChange}
        handleCategoriesDropdownChange={this.handleCategoriesDropdownChange}
        handleManagementCategoriesDropdownChange={this.handleManagementCategoriesDropdownChange}
        handleTitleTagsDropdownChange={this.handleTitleTagsDropdownChange}
        handleDescriptionTagsDropdownChange={this.handleDescriptionTagsDropdownChange}
        handleThumbnailImageInputChange={this.handleThumbnailImageInputChange}
        handleCoverImageInputChange={this.handleCoverImageInputChange}
        handleCoverImageToggleChange={this.handleCoverImageToggleChange}
        handleMediumRelatedArticlesChange={this.handleMediumRelatedArticlesChange}
        handlePreviewKeyChange={this.handlePreviewKeyChange}
        handleStatusMenuItemClick={this.handleStatusMenuItemClick}
        handleEditorWarning={this.handleEditorWarning}
        restoreArticle={this.restoreArticle}
        sendArticleData={this.sendArticleData}
        sendArticleTagIdsData={this.sendArticleTagIdsData}
        retrieveDraft={this.retrieveDraft}
        retrieveArticle={this.retrieveArticle}
        initializeFormValues={this.initializeFormValues}
        getAllArticleTags={this.getAllArticleTags}
        getRequestParameters={this.getRequestParameters}
        debouncedTitleAutoSave={this.debouncedTitleAutoSave}
        debouncedSummaryAutoSave={this.debouncedSummaryAutoSave}
        debouncedDescriptionAutoSave={this.debouncedDescriptionAutoSave}
        debouncedAutoSave={this.debouncedAutoSave}
        mergeTagsByArticleId={this.mergeTagsByArticleId}
        handleSidebarMenuItemClick={this.handleSidebarMenuItemClick}
        handleSummaryChange={this.handleSummaryChange}
        handleWarningModalClose={this.handleWarningModalClose}
        handleCancelButtonWarningModalClick={this.handleWarningModalClose}
        handleSubmitButtonWarningModalClick={this.handleSubmitButtonWarningModalClick}
      />
    )
  }
}
