import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popup, Input as DisplayInput } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import _ from 'lodash'

const DATETIME_TEXT_FORMAT = 'YYYYMMDDHHmm'

const propTypes = {
  /**
   * Initial value
   */
  value: PropTypes.object,

  /**
   * Value use to reset (on Clear) datetime picker
   */
  resetValue: PropTypes.object,

  /**
   * Form text input `name`
   */
  name: PropTypes.string,

  /**
   * Text input placeholder
   */
  placeholder: PropTypes.string,

  /**
   * DateRangePicker locale
   */
  locale: PropTypes.object,

  /**
   * Datetime display format
   */
  format: PropTypes.string,

  /**
   * Popup message on invalid input
   */
  invalidDatetimeMessage: PropTypes.string,

  /**
   * Position of popup message, see Popup ('semantic-ui-react') documentation
   */
  invalidDatetimeMessagePosition: PropTypes.string,

  /**
   * Callback on change value (chosen with DateRangePicker or finish typing on text input)
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  value: null,
  resetValue: moment(),
  locale: {
    applyLabel: '確定',
    cancelLabel: '解除',
  },
  placeholder: '',
  invalidDatetimeMessage: '',
  invalidDatetimeMessagePosition: 'top right',
}

function getFormatDatetime(value, format = undefined) {
  return _.isEmpty(value) ? '' : value.format(format)
}

function isSameDatetime(value, other) {
  return _.isEqual(getFormatDatetime(value), getFormatDatetime(other))
}

class DatetimeTextPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // value to input and display
      displayValue: '',
    }

    // value for picker
    this.datetimeValue = this.props.value
    // check input value
    this.isValidDatetimeText = true
  }

  /**
   * Parse from text input to datetime
   */
  parseDatetimeFromText = value => {
    const textDatetime = _.isEmpty(value) ? '' : value.replace(/\D/g, '')
    // specific format handling here, but now is empty (use default parsing from `moment`)

    const datetimeValue = moment(textDatetime, DATETIME_TEXT_FORMAT)
    return datetimeValue.isValid() ? datetimeValue : null
  }

  /**
   * Display value on input (when a value was set)
   */
  getDisplayText = datetimeValue => {
    return getFormatDatetime(datetimeValue, this.props.format)
  }

  /**
   * Display value on DateRangePicker
   */
  getDisplayDatetime = () => {
    return _.defaultTo(this.datetimeValue, this.props.resetValue)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isSameDatetime(this.props.value, nextProps.value)) {
      this.setState({
        displayValue: this.getDisplayText(nextProps.value),
      })
      this.datetimeValue = nextProps.value
      this.isValidDatetimeText = true
    }
  }

  /**
   * Set new value and emit the change to outside
   */
  setDatetimeValue = (event, datetimeValue) => {
    this.datetimeValue = datetimeValue
    this.isValidDatetimeText = true

    this.setState({
      displayValue: this.getDisplayText(datetimeValue),
    })

    // emit the change to outside
    if (this.props.onChange) {
      this.props.onChange(event, { name: this.props.name, value: datetimeValue })
    }
  }

  /**
   * Emit an invalid text input to outside
   */
  emitInvalidDatetime = (event, textValue) => {
    if (this.props.onInvalid) {
      this.props.onInvalid(event, { name: this.props.name, value: textValue })
    }
  }

  /**
   * Handle events happen on DateRangePicker
   */
  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setDatetimeValue(event, picker.startDate)
    } else if (event.type === 'cancel') {
      this.setDatetimeValue(event, null)
    } else if (event.type === 'show') {
      // permanent solution for bug: startDate != endDate
      if (!isSameDatetime(picker.oldEndDate, picker.oldStartDate)) {
        picker.setEndDate(picker.startDate)
        picker.isShowing = false
        // in this method, the `oldStartDate` and `oldEndDate` were sync with `startDate` and `endDate`
        picker.show()
      }
      // on typing, do not show the dropdown
      // if (!_.isEmpty(this.state.displayValue)) {
      //   picker.container.hide()
      // }
    }
  }

  /**
   * Handle change event happen on text input
   */
  handleTextInputChange = event => {
    const displayValue = event.target.value
    const parsedDatetime = this.parseDatetimeFromText(displayValue)

    // empty text is also a valid
    this.isValidDatetimeText = _.isEmpty(displayValue) || !_.isEmpty(parsedDatetime)
    this.datetimeValue = parsedDatetime

    this.setState({ displayValue })

    if (!this.isValidDatetimeText) {
      this.emitInvalidDatetime(event, displayValue)
    }
  }

  /**
   * Called when user finish typing on text input
   */
  onFinishTyping = event => {
    const datetimeValue = this.datetimeValue

    if (this.isValidDatetimeText) {
      this.setState({
        displayValue: this.getDisplayText(datetimeValue),
      })

      if (!isSameDatetime(datetimeValue, this.props.value)) {
        this.setDatetimeValue(event, datetimeValue)
      }
    }
  }

  /**
   * Handle blur event (means ignore editing) happen on text input
   */
  handleTextInputBlur = event => {
    if (this.isValidDatetimeText) {
      this.datetimeValue = this.props.value
      this.setState({
        displayValue: this.getDisplayText(this.datetimeValue),
      })
    }
  }

  /**
   * Handle keypress event happen on text input to catch `Enter` press
   */
  handleTextInputKeyPress = event => {
    if (event.key === 'Enter') {
      // if form value was empty => use reset value as the choice
      // if empty because of clearing (input text was blank) => use blank value
      if (_.isEmpty(this.datetimeValue) && _.isEmpty(this.props.value) && !_.isEmpty(this.state.displayValue)) {
        this.datetimeValue = this.props.resetValue
      }
      event.preventDefault()
      this.onFinishTyping(event)

      event.target.blur()
    }
  }

  /**
   * Handle focus event (means start typing) happen on text input
   */
  handleTextInputFocus = event => {
    // for an invalid text input, allow to edit it; else, reset the text
    if (this.isValidDatetimeText) {
      const inputElement = event.target
      const displayDatetime = this.getDisplayDatetime()

      this.setState({ displayValue: getFormatDatetime(displayDatetime, 'YYYYMMDD HHmm') }, () => {
        const timeSectionStart = inputElement.value.length - 4
        const timeSectionEnd = inputElement.value.length
        inputElement.setSelectionRange(timeSectionStart, timeSectionEnd)
      })
    }
  }

  render() {
    const displayDatetime = this.getDisplayDatetime()

    return (
      <DateRangePicker
        containerStyles={{ display: 'block' }}
        singleDatePicker
        timePicker
        timePicker24Hour
        locale={this.props.locale}
        startDate={displayDatetime}
        endDate={displayDatetime}
        onEvent={this.handleDatePickerEvent}
      >
        <Popup
          open={!this.isValidDatetimeText}
          content={this.props.invalidDatetimeMessage}
          className="red"
          position={this.props.invalidDatetimeMessagePosition}
          inverted
          trigger={
            <DisplayInput
              icon="calendar"
              iconPosition="left"
              placeholder={this.props.placeholder}
              value={this.state.displayValue}
              onChange={this.handleTextInputChange}
              onBlur={this.handleTextInputBlur}
              onFocus={this.handleTextInputFocus}
              onKeyPress={this.handleTextInputKeyPress}
            />
          }
        />
        <Form.Input name={this.props.name} value={getFormatDatetime(this.props.value)} readOnly className="isHidden" />
      </DateRangePicker>
    )
  }
}

DatetimeTextPicker.propTypes = propTypes
DatetimeTextPicker.defaultProps = defaultProps

export default DatetimeTextPicker
